import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react'
import {
  Modal,
  Input,
  Spin,
  Affix,
  Badge,
  Drawer,
  Divider,
  Form,
  InputNumber,
  Select,
  Radio,
  Space,
  Collapse,
  DatePicker,
} from 'antd'
import { Link, useLocation, } from "react-router-dom";
import {
  LangSwitch,
  Header,
  Header2,
  ContactButton,
  Wrapper,
  GlobalStyle,
  readQrCodeCookie,
  t,
  HeadText,
  CartButton,
  Button,
  ScanQRAgain,
  ProvidersData,
} from './Common.js'
import { CaretRightOutlined, CaretLeftOutlined, EnvironmentOutlined, ClockCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Link as ScrollLink, Element, scroller } from 'react-scroll'

import axios from 'axios'
import { formatPrice, imageUrl } from '../utils'
import styled from 'styled-components'
import { get, set, isEqual, isNumber, values, sumBy, toPairs, isArray, groupBy, range, flatMap, reduce, debounce } from 'lodash'
import { useSpring, config as springConfigs } from 'react-spring'
import { Textfit } from 'react-textfit';
import { format } from 'date-fns'

const menu_cache = {}
const loadMenu = (type) => (hotelId) => {
  if (!hotelId) return Promise.resolve({})
  if (!menu_cache[type]) {
    return axios.get(`/api/shop/${hotelId}/hotel/menu/${type}`).then(({ data }) => {
      menu_cache[type] = data
      return data
    }).catch(() => {
    })
  } else {
    return Promise.resolve(menu_cache[type])
  }
}

const RestaurantPage = ({ hotelId, restaurantNum }) => {
  const [menu, setMenu] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loadMenu('restaurant' + restaurantNum)(hotelId).then((data) => {
      setMenu(data)
      setLoading(false)
    }).catch(() => {
    })
  }, [])
  if (!hotelId) {
    return <ScanQRAgain />
  }
  if (loading) {
    return <Spin style={{ margin: '150px auto', display: 'block' }} />
  }

  const welcomeText = (t(menu.welcome_msg || {}) || t({
    pl: "Witaj w hotelowej restauracji!\nPrzejrzysz oraz wybierzesz tutaj produkty z menu!\nGdy będziesz gotowy, kelner podejdzie aby przyjąć Twoje zamówienie.",
    en: "Welcome to the hotel restaurant!\nYou can browse and select products from the menu here!\nWhen you are ready, the waiter will come to take your order.",
  })).split("\n")

  return <div style={{
    textAlign: 'center',
  }}>
    <Header2 hotelId={hotelId} />
    <HeadText>{welcomeText[0]}</HeadText>

    {welcomeText.splice(1).map((x, i) => <p key={i} style={{
      color: 'var(--primary)',
    }}>{x}</p>)}

    <Link to={`/hotel/restaurant${restaurantNum}/menu`}>
      <Button
        type="primary"
        size="large"
        style={{ fontSize: 24, height: 'auto', width: '100%', marginTop: 36 }}
      >
        {t({ pl: "ZOBACZ MENU", en: "GO TO MENU" })}
      </Button>
    </Link>
  </div>
}

const ProductWrapper = styled.div`
border: 1px solid var(--primary);
box-shadow: var(--shadow-base);
padding: 8px 12px 12px;
margin: 12px auto;
border-radius: 6px;
width: 100%;
background-color: #fff;
transition: 0.3s border-color, 0.3s background-color;
font-size: 16px;
`

const ServicePopup = ({ serviceModalVisible, product, setCart, cart, close, category, productKey }) => {

  const serviceType = category.name?.en

  const onFinish = (values) => {
    setCart([
      ...cart,
      {
        productKey: productKey,
        category: category.name,
        product: product,
        ...values,
      }
    ])
    close()
  }

  const addWithoutForm = (product.provider == "zoo") || (product.provider == "mpw" && serviceType == "Souvenirs")

  useLayoutEffect(() => {
    if (serviceModalVisible && addWithoutForm) {
      onFinish({})
    }
  }, [serviceModalVisible])

  if (addWithoutForm) return <div />

  return <Modal
    visible={serviceModalVisible}
    footer={null}
    onCancel={close}
  >
    <Wrapper style={{ padding: 0 }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 600,
        fontSize: 18,
        marginTop: 18,
        color: 'var(--primary)',
      }}>
        <div>{t(product.name)}</div>
        {!!product.price && <div style={{
          minWidth: 90,
          textAlign: 'right',
        }}>{isNumber(product.price) ? formatPrice(product.price) : product.price} PLN</div>}
      </div>
      <p style={{ fontSize: 16 }}>{t(product.desc)}</p>
      <Divider />
      <Form
        size="large"
        onFinish={onFinish}
        initialValues={{
          people_count: 1,
          items_count: 1,
          hour: '12:00',
        }}
      >
        {["Gym", "Spa"].includes(serviceType) &&
          <Form.Item
            name="people_count"
            label="For how many people?"
            rules={[{ required: true, message: 'required' }]}
          >
            <InputNumber min={1} max={8} />
          </Form.Item>}

        {"Laundry" == serviceType &&
          <Form.Item
            name="items_count"
            label="How many items?"
            rules={[{ required: true, message: 'required' }]}
          >
            <InputNumber min={1} max={100} />
          </Form.Item>}

        {("Tickets" == serviceType || product.provider == 'manubbies') &&
          <Form.Item
            name="date"
            label="Choose date:"
            rules={[{ required: true, message: 'required' }]}
          >
            <DatePicker />
          </Form.Item>}

        <Form.Item
          name="hour"
          label="Choose hour for reservation::"
          rules={[{ required: true, message: 'required' }]}
        >
          <Select
            options={flatMap(range(10, 18), h => ['00', '30'].map(m => {
              const time = `${h}:${m}`
              return { label: time, value: time }
            }))}
          />
        </Form.Item>
        <Form.Item
          name="notes"
          label="Other requests::"
        >
          <Input.TextArea />
        </Form.Item>
        <div style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit">
            ADD
          </Button>
        </div>
      </Form>
    </Wrapper>
  </Modal>
}

const Product = ({ product, productIdx, activeProduct, setActiveProduct, setCart, cart, categoryIdx, category }) => {
  const [serviceModalVisible, setServiceModalVisible] = useState(false)

  const desc = product.desc && t(product.desc)
  const productKey = [categoryIdx, productIdx]
  const inCart = isArray(cart) ? cart.filter(x => isEqual(x.productKey, productKey)).length : get(cart, productKey, 0)
  const active = isEqual(productKey, activeProduct) //|| inCart > 0

  const openServiceModal = () => {
    setServiceModalVisible(true)
  }

  const onClick = false //product.isService
    ? () => openServiceModal()
    : (e) => {
      if (active) {
        if (!e.target.closest('.no-hide')) {
          setActiveProduct(null)
        }
      } else {
        setActiveProduct(productKey)
      }
    }

  return <div><ProductWrapper
    onClick={onClick}
    style={{
      //border: '1px solid var(--primary)',
      backgroundColor: inCart > 0 ? 'rgba(80, 158, 47, 0.1)' : '#fff',
      transition: 'background-color 0.3s',
    }}
  >
    {product.image && <div style={{
      margin: '-8px -12px 8px',
    }}>
      <img
        src={imageUrl(product.image)}
        style={{
          width: '100%',
          maxHeight: 300,
          objectFit: 'cover',
          objectPosition: 'center',
          display: 'block',
          borderRadius: '6px 6px 0 0'
        }}
      />
    </div>}
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      fontWeight: 600,
      fontSize: 18,
      color: 'var(--primary)',
    }}>
      <div>{t(product.name)}</div>
      {!!product.price && <div style={{
        minWidth: 90,
        textAlign: 'right',
      }}>{isNumber(product.price) ? formatPrice(product.price) : product.price} PLN</div>}
    </div>
    {!!desc && <p style={{
      marginTop: 12,
      marginBottom: 0,
    }}>{desc}</p>}

    <div style={{
      padding: active ? '12px 24px 0' : '0 0',
      height: active ? 51 : 0,
      transition: 'height 0.3s, padding 0.3s',
      overflow: 'hidden',
    }}
      className="no-hide"
    >
      <CartButton
        limit={100}
        inCart={inCart}
        setInCart={(x) => {
          if (product.isService) {
            openServiceModal()
            return
          }

          const c = { ...cart }
          set(c, productKey, x)
          setCart(c)
        }}
      />
    </div>
  </ProductWrapper>
    {product.isService &&
      <ServicePopup
        serviceModalVisible={serviceModalVisible}
        product={product}
        category={category}
        setCart={setCart}
        cart={cart}
        close={() => setServiceModalVisible(false)}
        productKey={productKey}
      />
    }
  </div>
}

const Category = ({ category, categoryIdx, activeProduct, setActiveProduct, setCart, cart, oneCategory }) => {
  return <Element style={{
    paddingBottom: 36,
  }}
    name={'' + categoryIdx}
  >

    <div style={{
      textAlign: 'center',
      fontSize: 16,
      color: 'var(--primary)',
    }}>
      <p style={{
        fontWeight: 'bold',
        fontSize: 20,
        marginBottom: 8,
      }}>{t(category.name)}</p>
      <p>{t(category.desc)}</p>
    </div>
    <div>
      {(category.items || []).map((x, i) => x.header
        ? <p key={i} style={{
          fontWeight: 'bold',
          marginTop: 24,
          fontSize: 18,
          color: 'var(--primary)',
        }}>{t(x.header)}</p>
        : <Product
          product={x}
          productIdx={i}
          key={i}
          activeProduct={activeProduct}
          setActiveProduct={setActiveProduct}
          setCart={setCart}
          cart={cart}
          categoryIdx={categoryIdx}
          category={category}
        />)}
    </div>
  </Element>
}


const CategoryBox = styled.div`
maxWidth: 100px;
height: 100px;
font-size: 14px;
border: 1px solid var(--primary);
border-radius: 6px;
margin: 3px;
padding: 3px;
text-align: center;
overflow: hidden;
color: var(--primary);
box-shadow: var(--shadow-base);
//font-weight: 600;
//transition: 0.3s background-color;
&:hover { color: var(--primary); }
.active > & {
  color: #fff;
  background: var(--primary);
}
`

const Slider = ({
  children,
  activeIdx,
}) => {
  const [firstVisible, setFirstVisible] = useState(0)

  const itemsRef = useRef([])
  const scrollerRef = useRef()
  const inRow = 3
  const itemWidth = "33.33%"

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, children.length);
  }, [children]);

  const [scrollSpring, animateScroll] = useSpring(() => ({
    to: { val: 0 },
    config: { mass: 0.5, tension: 210, friction: 20 }, //springConfigs.stiff,
    onChange: ({ value }) => {
      scrollerRef.current.scrollLeft = value.val
    }
  }))

  const scrollTo = (idx) => {
    if (idx < 0 || idx > children.length - inRow) { return }
    animateScroll.start({
      from: { val: scrollerRef.current.scrollLeft },
      to: { val: idx * itemsRef.current[0].offsetWidth }
    })
  }

  const handleScroll = debounce((e) => {
    setFirstVisible(Math.floor((scrollerRef.current.scrollLeft + 1) / itemsRef.current[0].offsetWidth))
  }, 16)

  const scrollToActive = (activeIdx, firstVisible) => {
    if (activeIdx <= firstVisible) scrollTo(Math.max(0, activeIdx - 1))
    if (activeIdx >= firstVisible + 3) scrollTo(Math.min(children.length - inRow, activeIdx - 2))
  }
  const scrollToActiveD = useCallback(debounce(scrollToActive, 200), [])
  useLayoutEffect(() => scrollToActiveD(activeIdx, firstVisible), [activeIdx])

  return <div style={{
    display: 'flex',
    alignItems: 'center',
    color: "var(--primary)",
    fontSize: 26,
  }}>
    <CaretLeftOutlined
      onClick={() => scrollTo(firstVisible - 1)}
      style={{
        opacity: firstVisible > 0 ? 1 : 0,
      }}
    />
    <div
      ref={scrollerRef}
      onScroll={handleScroll}
      style={{
        flex: 1,
        overflow: 'scroll',
        display: 'flex',
        paddingBottom: 1,
      }}>
      {children.map((x, i) => <div
        key={i}
        ref={el => itemsRef.current[i] = el}
        style={{
          width: itemWidth,
          flexShrink: 0,
          textAlign: 'center',
        }}>{x}</div>)}
    </div>
    <CaretRightOutlined
      onClick={() => scrollTo(firstVisible + 1)}
      style={{
        opacity: firstVisible < children.length - inRow ? 1 : 0,
      }}
    />
  </div>
}


const CategoriesMenu = ({ categories, oneCategory }) => {
  useLayoutEffect(() => {
    const hash = window.location.hash
    if (!oneCategory && hash) {
      setTimeout(() => scroller.scrollTo(hash.substr(1), { offset: -110 }), 0)
    }
  }, [])

  const [active, setActive] = useState(0)

  const LinkElem = ({ i, children }) => {
    if (oneCategory) {
      const hash = window.location.hash
      return <Link to={`#${i}`} className={hash == `#${i}` ? "active" : ""}>
        {children}
      </Link>
    } else {
      return <ScrollLink
        key={i}
        activeClass="active"
        to={'' + i}
        spy={true}
        //hashSpy={true}
        //saveHashHistory={false}
        smooth={true}
        offset={-110}
        duration={x => Math.min(400, Math.abs(x) / 2)}
        onSetActive={() => {
          setActive(i)
        }}
      >
        {children}
      </ScrollLink>
    }
  }

  return <Slider
    activeIdx={active}
  >
    {categories.map((x, i) => <LinkElem i={i} key={i}>
      <CategoryBox>
        <Textfit
          min={8}
          max={18}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            lineHeight: '1.2em',
          }}
          mode="multi"
        >
          {t(x.name)}
        </Textfit>
      </CategoryBox>
    </LinkElem>)}
  </Slider>
}

const CartList = ({ cart, menu }) => {

  return <div style={{
    fontSize: 20,
    fontFamily: 'FS Lola',
  }}>
    <p style={{
      fontSize: 24,
      fontWeight: 600,
      color: "var(--primary)",
    }}>{t({ pl: 'Twój wybór', en: 'Your choice' })}</p>
    <p style={{
      textAlign: 'center',
      fontStyle: 'italic',
      fontWeight: 'bold',
      marginBottom: -18,
      fontSize: 16,
    }}>
      {t({
        pl: "Gdy będziesz gotowy, kelner podejdzie aby przyjąć Twoje zamówienie.",
        en: "When you're ready the waiter will come in to take your order"
      })}
    </p>
    <Divider />

    {menu.categories.map((category, i) => {
      const items = toPairs(cart[i]).filter(([id, q]) => q > 0)
      if (items.length) {
        return <div key={i}>
          <p style={{
            fontWeight: 600,
            color: "var(--primary)",
            marginBottom: 8,
          }}>{t(category.name)}</p>
          <ul>
            {items.map(([id, q]) => <li key={id}>
              {t(category.items[id].name)}
              {q > 1 && ` (x${q})`}
            </li>)}
          </ul>
        </div>
      }
    })}
    <p style={{
      color: "var(--primary)",
      textAlign: 'center',
      fontWeight: "bold",
    }}>&mdash;&mdash;&mdash;</p>
  </div>
}

const ServicesCart = ({ cart, close, hotelId }) => {
  const [summary, setSummary] = useState(false)
  const [thx, setThx] = useState(false)

  console.log(cart)

  const total = reduce(cart, (s, item) => s + (+(item.product.price || 0)), 0)

  const closeAll = () => {
    setSummary(false)
    setThx(false)
    close()
  }

  const Products = ({ prices }) => toPairs(groupBy(cart, x => t(x.category))).map(([category, items], i) => <div key={i}>
    <p style={{
      fontSize: '1.2em',
      fontWeight: 600,
    }}>{category}</p>
    {items.map((item, i) => {
      return <div key={i}>
        {!!prices && !!item.product.price && <p style={{ float: 'right' }}>{item.product.price} PLN</p>}
        <p style={{
          fontWeight: 600,
          marginBottom: 0,
        }}>{t(item.product.name)}</p>
        <p>
          {item.people_count && <>{item.people_count} {item.people_count > 1 ? 'people' : 'person'},</>}
          {item.items_count && <>{item.items_count} {item.items_count > 1 ? 'items' : 'item'},</>}
          {item.date && (" " + format(new Date(item.date), "dd.MM.yyyy") + ", ")}
          {item.hour && (" " + item.hour)}
          <br />
          {item.notes && ('Requests: ' + item.notes)}
        </p>
      </div>
    })}
  </div>
  )

  const Summary = () => <div>
    <p style={{
      fontSize: '1.5em',
      marginBottom: 0,
    }}>{t({ pl: 'Wartość zamówienia', en: 'Total price' })}: <b>{total.toFixed(0)} PLN</b></p>

    <p style={{
      fontWeight: 600,
      marginTop: 36,
    }}>Payment method:</p>
    <Radio.Group size="large">
      <Space direction="vertical">
        <Radio style={{ fontSize: 16 }} value={1}>Add to the room bill</Radio>
        <Radio style={{ fontSize: 16 }} value={2}>Pay online</Radio>
      </Space>
    </Radio.Group>

  </div>

  return <div style={{
    fontSize: 16,
    fontFamily: 'FS Lola',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    color: 'var(--primary)',
  }}>
    <p style={{
      fontSize: '1.5em',
      fontWeight: 600,
      marginBottom: 0,
    }}>{t({ pl: 'Twoje zamówienie', en: 'Your order' })}</p>

    <Divider />

    <div style={{
      flex: 1,
      overflow: 'scroll',
    }}>
      {!summary ? <Products prices /> : <Summary />}
    </div>

    <div style={{ textAlign: 'center' }}>
      <Button
        type="primary"
        size="large"
        onClick={() => {
          if (summary) {
            setThx(true)
          } else {
            setSummary(true)
          }
        }}
      >Order</Button>
    </div>

    <Drawer
      visible={thx}
      onClose={closeAll}
      placement="bottom"
      height="100%"
    >
      <Wrapper>
        <Header2 hotelId={hotelId} />
        <p style={{
          textAlign: 'center',
          fontWeight: 600,
          fontSize: '1.5em',
          color: 'var(--primary)',
        }}>Thank you for your order!</p>
        <Collapse style={{ fontSize: 16, marginBottom: 36 }}>
          <Collapse.Panel header="See order details" key="1">
            <Products />
          </Collapse.Panel>
        </Collapse>
        <p style={{ textAlign: 'center', color: 'var(--primary)' }}>Want to change something?</p>
        <ContactButton />
      </Wrapper>
    </Drawer>

  </div>
}

const ProviderHeader = ({ bg, hours, distance, name, category, address, desc, logo }) => {
  return <div style={{
    color: "var(--primary)",
  }}>
    <div style={{
      margin: '-12px -12px 0',
      position: 'relative',
    }}>
      <img
        src={bg}
        style={{
          width: "100%",
          maxHeight: 375,
          objectFit: 'cover',
          objectPosition: "top",
        }}
      />
      <div style={{
        position: "absolute",
        bottom: -16,
        left: "50%",
        marginLeft: -70,
        width: 140,
        height: 140,
        borderRadius: "50%",
        background: "#fff",
        overflow: 'hidden',
        padding: 8,
      }}>
        <img
          src={logo}
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain',
          }}
        />
      </div>
    </div>
    <div style={{
      textAlign: 'left',
      fontSize: 16,
      marginTop: 12,
    }}>
      <div style={{ float: 'right' }}>
        <ClockCircleOutlined /> {hours}<br />
        <InfoCircleOutlined /> Info
      </div>
      <div style={{ margin: '8px 0' }}><b>{name}</b></div>
      <div>{category}</div>
      <div style={{ margin: '8px 0' }}><EnvironmentOutlined /> <b>{distance}</b>, {address}</div>
      <div style={{
        lineHeight: '1.3em',
        marginBottom: 12,
      }}>{t(desc)}</div>
      <div style={{
        textAlign: 'center',
        marginTop: 24,
        marginBottom: 36,
      }}>
        <Link to="/regulaminy" style={{ color: "var(--primary)", textDecoration: "underline" }}>
          {t({ en: "Privacy policy and regulations", pl: "Polityka prywatności i regulaminy" })}
        </Link>
      </div>
    </div>
  </div>
}

const ProductsPage = ({
  hotelId,
  loadProducts,
  initCart,
  cartComponent,
  cartText,
  oneCategory,
  provider,
}) => {
  const [products, setProducts] = useState({})
  const [loading, setLoading] = useState(true)
  const [cart, setCart] = useState(initCart)
  const [activeProduct, setActiveProduct] = useState([0, 0])
  const [listOpened, setListOpened] = useState(false)

  const hash = useLocation().hash

  useEffect(() => {
    loadProducts(hotelId).then((data) => {
      setProducts(data)
      setLoading(false)
    }).catch(() => {
    })
  }, [])

  const cartSize = isArray(cart) ? cart.length : sumBy(values(cart), (x) => sumBy(values(x)))
  const CartComponent = cartComponent
  if (!hotelId) {
    return <ScanQRAgain />
  }

  if (loading) {
    return <Spin style={{ margin: '150px auto', display: 'block' }} />
  }

  return <div>

    {!provider && <Header hotelId={hotelId} />}
    {provider && <ProviderHeader {...ProvidersData[provider]} />}

    <Affix>
      <div style={{ background: '#fff', margin: '0 -12px', padding: "18px 0" }}>
        <CategoriesMenu categories={products.categories} oneCategory={oneCategory} />
      </div>
    </Affix>

    <div style={{
      marginTop: 24,
      marginBottom: 70,
    }}>
      {products.categories.map((x, i) => (!oneCategory || ((hash ? hash.substr(1) : 0) == i)) && <Category
        category={x}
        key={i}
        categoryIdx={i}
        activeProduct={activeProduct}
        setActiveProduct={setActiveProduct}
        setCart={setCart}
        cart={cart}
        oneCategory={oneCategory}
      />)}
    </div>

    <div style={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      padding: '8px 0',
      background: 'rgba(255, 255 ,255, 0.9)',
      boxShadow: 'rgb(0 0 0 / 5%) 0px -1px 2px',
    }}>
      <div style={{ margin: '0 auto', width: 200, }}>
        <Badge count={cartSize}>
          <Button
            style={{
              width: 200,
              height: 'auto',
              fontSize: '20px',
              fontWeight: '500',
            }}
            type="primary"
            onClick={() => setListOpened(true)}
            disabled={cartSize == 0}
          >
            {cartText}
          </Button>
        </Badge>
      </div>
    </div>
    <Drawer
      visible={listOpened}
      onClose={() => setListOpened(false)}
      placement="bottom"
      height={550}
    >
      <CartComponent
        key={listOpened ? "opened" : "closed"}
        menu={products}
        cart={cart}
        close={() => setListOpened(false)}
        hotelId={hotelId}
      />
    </Drawer>
  </div>
}

const RestaurantMenuPage = ({ hotelId, lang, restaurantNum }) => {
  return <ProductsPage
    hotelId={hotelId}
    loadProducts={loadMenu('restaurant' + restaurantNum)}
    initCart={{}}
    cartComponent={CartList}
    cartText={t({
      pl: 'Twój wybór',
      en: 'Your choice',
    })}
  />
}

const ServicesPage = ({ hotelId, lang, provider }) => {
  const load = (hotelId) => {
    return loadMenu(provider || 'services')(hotelId).then((data) => {
      //if (!provider) {
      for (var c of data.categories || []) {
        for (var p of c.items || []) {
          p.isService = true
          p.provider = provider
        }
      }
      //}
      return data
    })
  }
  return <ProductsPage
    hotelId={hotelId}
    loadProducts={load}
    initCart={[]}
    cartComponent={ServicesCart}
    oneCategory={!provider}
    cartText={t({
      pl: 'Twoje zamówienie',
      en: 'Your order',
    })}
    provider={provider}
  />
}

export {
  RestaurantPage,
  RestaurantMenuPage,
  ServicesPage,
}
