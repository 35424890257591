import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react'
import {
  Button as AntButton,
  Modal,
  Input as AntInput,
  Spin,
  Button,
  Popover
} from 'antd'
import { ArrowRightOutlined, ClockCircleOutlined, EnvironmentOutlined } from '@ant-design/icons'
import styled, { css, createGlobalStyle } from 'styled-components'
import axios from 'axios'
import { inject, observer } from 'mobx-react'
import { size, omit, isEmpty, keys, sortBy, values, find, range, sumBy } from 'lodash'
import { Router, useRouteMatch, Switch, Route, Link, useHistory, Redirect } from "react-router-dom";
import { state } from '../models/AppState'

import { BreakfastPageInner } from './Breakfast'
import { RestaurantPage, RestaurantMenuPage, ServicesPage } from './Restaurant'
import ShopPage from './Shop'

import {
  Header,
  Header2,
  Wrapper,
  GlobalStyle,
  readQrCodeCookie,
  t,
  HeadText,
  ScanQRAgain,
  ProvidersData,
} from './Common.js'
import config from '../config'

const GlobalStyle2 = createGlobalStyle`
:root {
  --primary: ${props => props.primaryColor || "rgb(27,26,78)" || "#1F4355"};
}
`

const A = (args) => {
  if (args.disabled) {
    return <div {...args} />
  }
  if (args.href) {
    return <a {...args} />
  } else {
    return <Link {...args} />
  }
}

const SectionButtonInner = styled(A)`
padding: 14px 8px 12px;
display: block;
border: 2px solid var(--primary);
border-radius: 20px;
color: var(--primary);
font-weight: 600;
width: 100%;
text-align: center;
&:hover {
  color: var(--primary);
}
&:active {
  background: var(--primary);
  color: #fff;
}
transition: background-color 0.3s, color 0.3s;
`

const SectionButton = ({ to, href, icon, text, style, disabled }) => {
  return <SectionButtonInner
    to={to}
    href={href}
    disabled={disabled}
    style={{
      opacity: disabled ? 0.4 : 1,
      ...style,
    }}
  >
    <i className={`icon-${icon}`} style={{ fontSize: 52 }} /><br />
    {text}
  </SectionButtonInner>
}

const ButtonsGrid = styled.div`
  display: grid;
  grid: 1fr / 1fr 1fr;
  row-gap: 24px;
  column-gap: 24px;
`

const WelcomePage = ({ hotelId }) => {
  return <div style={{
    textAlign: 'center',
  }}>
    <Header2 hotelId={hotelId} />
    <HeadText>
      {t({
        pl: "Witaj! Tutaj zamówisz usługi i przejrzysz ofertę hotelową.",
        en: "Welcome to the hotel ordering system!"
      })}
    </HeadText>

    <ButtonsGrid>
      <SectionButton
        text={t({ pl: 'Jedzenie i napoje', en: 'Food & Drinks' })}
        icon="Plates"
        to="/hotel/restaurant"
      />
      <SectionButton
        text={t({ pl: 'Usługi hotelowe', en: 'Hotel services' })}
        icon="Slippers"
        to="/hotel/services#0"
      />
      <SectionButton
        text={t({ pl: 'Sklep hotelowy', en: 'Hotel store' })}
        icon="Bag-Coins"
        to="/hotel/shop"
      />
      <SectionButton
        text={t({ pl: 'Explore locally', en: 'Explore locally' })}
        icon="Map2"
        to="/hotel/explore"
      />
      <SectionButton
        text={t({ pl: 'Skontaktuj się z Front Desk', en: 'Contact the Front Desk' })}
        href="tel:"
        icon="Phone-2"
        style={{ gridColumn: "span 2" }}
      />
    </ButtonsGrid>
  </div>
}

const RestaurantSections = ({ hotelId }) => {
  return <div style={{
    textAlign: 'center',
  }}>
    <Header2 hotelId={hotelId} />
    <HeadText>
      {t({
        pl: "Witaj! Tutaj zamówisz usługi i przejrzysz ofertę hotelową.",
        en: "Welcome to the hotel ordering system!"
      })}
    </HeadText>

    <ButtonsGrid>
      <SectionButton
        text={t({ pl: <>Menu<br />restauracji</>, en: <>Restaurant<br />menu</> })}
        icon="Plates"
        to="/hotel/restaurant/menu"
      />
      <SectionButton
        text={t({ pl: <>Minibar<br />online</>, en: <>Minibar<br />online</> })}
        icon="Cocktail"
        to="/hotel/restaurant/menu#7"
      />
      <SectionButton
        text={t({ pl: 'Śniadania', en: <>Room<br />Breakfast</> })}
        icon="Croissant"
        to="/hotel/breakfast"
      />
      <SectionButton
        text={t({ pl: <>Życzenia<br />Specjalne</>, en: <>Special<br />Wishes</> })}
        icon="Chef"
        to="/hotel/other"
      />
    </ButtonsGrid>
  </div>
}

const ServicesSections = ({ hotelId }) => {
  return <div style={{
    textAlign: 'center',
  }}>
    <Header2 hotelId={hotelId} />
    <HeadText>
      {t({
        pl: "Witaj w systemie zamawiania i rezerwacji usług hotelowych!",
        en: "Welcome to the hotel services booking and reservation system!"
      })}
    </HeadText>

    <ButtonsGrid>
      <SectionButton
        text={t({ pl: 'Room Service', en: 'Room Service' })}
        icon="Door-Hanger"
        to="/hotel/services#0"
      />
      <SectionButton
        text={t({ pl: 'Siłownia', en: 'Gym' })}
        icon="Bodybuilding"
        to="/hotel/services#1"
      />
      <SectionButton
        text={t({ pl: 'Spa', en: 'Spa' })}
        icon="Slippers"
        to="/hotel/services#2"
      />
      <SectionButton
        text={t({ pl: 'Pranie', en: 'Laundry' })}
        icon="Shirt"
        to="/hotel/services#3"
      />
    </ButtonsGrid>
  </div>
}

const ShoppingSections = ({ hotelId }) => {
  return <div style={{
    textAlign: 'center',
  }}>
    <Header2 hotelId={hotelId} />
    <HeadText>
      {t({
        pl: "Sklep hotelowy",
        en: "Hotel store"
      })}
    </HeadText>

    <ButtonsGrid>
      <SectionButton
        text={t({ pl: 'Sklep hotelowy', en: 'Hotel store' })}
        icon="Bag-Coins"
        to="/hotel/shop"
      />
      <SectionButton
        text={t({ pl: 'Lokalne sklepy', en: 'Local Shops' })}
        icon="Bag-Coins"
        to="/hotel/other"
      />
      <SectionButton
        text={t({ pl: 'Dzieci', en: 'Kids' })}
        icon="Bag-Coins"
        to="/hotel/other"
        style={{ gridColumn: "span 2" }}
      />
    </ButtonsGrid>
  </div>
}

const OrderServicePage = ({ hotelId }) => {
  return <div style={{
    textAlign: 'center',
  }}>
    <Header2 hotelId={hotelId} />
    <HeadText>
      {t({
        pl: "Potrzebujesz czegoś innego?",
        en: "Do you need something else?"
      })}
    </HeadText>
    <SectionButton
      text={t({ pl: 'Skontaktuj się z Front Desk', en: 'Contact the Front Desk' })}
      href="tel:"
      icon="Phone-2"
      style={{ gridColumn: "span 2" }}
    />
  </div>
}

const ExploreBox = ({ bg, logo, name, category, hours, distance, provider }) => {
  return <Link to={`/hotel/explore/${provider}`}>
    <div style={{
      border: "1px solid var(--primary)",
      position: "relative",
      marginBottom: 12,
      color: "var(--primary)",
      boxShadow: "var(--shadow-base)",
    }}>
      <img
        src={bg}
        style={{
          width: "100%",
          maxHeight: 165,
          minHeight: 100,
          objectFit: 'cover',
          objectPosition: "top",
        }}
      />
      <div style={{
        position: "absolute",
        top: 12,
        right: 12,
        width: 100,
        height: 100,
        borderRadius: "50%",
        background: "#fff",
        overflow: 'hidden',
        padding: 8,
      }}>
        <img
          src={logo}
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain',
          }}
        />
      </div>
      <div style={{
        padding: 12,
        textAlign: 'left',
        fontSize: 16,
      }}>
        <div style={{ float: 'right' }}>
          <ClockCircleOutlined /> {hours}<br />
          <EnvironmentOutlined /> {distance}
        </div>
        <div style={{  }}><b>{name}</b></div>
        <div>{category}</div>
      </div>
    </div>
  </Link>
}

const ExplorePage = ({ hotelId }) => {
  return <div style={{
    textAlign: 'center',
  }}>
    <Header hotelId={hotelId} />
    <HeadText>Explore locally</HeadText>
    <ExploreBox
      {...ProvidersData['zoo']}
      provider="zoo"
    />
    <ExploreBox
      {...ProvidersData['mpw']}
      provider="mpw"
    />
    <ExploreBox
      {...ProvidersData['manubbies']}
      provider="manubbies"
    />
  </div>
}


/*
const RestaurantPage = ({ hotelId }) => {
  return <div style={{
    textAlign: 'center',
  }}>
    <Header2 hotelId={hotelId} />
    <HeadText>
      {t({
        pl: "Witaj w hotelowej restauracji!",
        en: "Welcome to the hotel restaurant!"
      })}
    </HeadText>

    <p style={{
      color: 'var(--primary)',
      fontSize: 22,
      marginBottom: 32,
    }}>{t({
      pl: 'Aby zamówić:',
      en: 'To order:'
    })}</p>
    <SectionButton
        text={t({ pl: 'Skontaktuj się z Front Desk', en: 'Contact the Front Desk' })}
        href="tel:"
        icon="Phone-2"
        style={{ gridColumn: "span 2" }}
      />
  </div>
}
*/

const QRCode = ({ }) => {
  const qrCookie = readQrCodeCookie() || {}
  const link = 'https://' + window.location.hostname + '/api/hotel-qr/' + [qrCookie['hotel-id'], qrCookie['qr-id'], qrCookie['token']].join('/').replace('//', '/')
  const img = `https://chart.googleapis.com/chart?cht=qr&chs=300x300&chl=${encodeURIComponent(link)}`
  return <div>
    <img
      src={img}
      style={{}}
    />
  </div>
}

const DemoPage = ({ hotelId, hasDemoPanel }) => {
  const history = useHistory()

  if (!hotelId) {
    return <ScanQRAgain />
  }

  const onMobile = window.innerWidth <= 500

  if (onMobile) {
    history.replace("/hotel")
    return <div />;
  }

  return <div style={{
    textAlign: 'center',
  }}>
    <img
      src="/logo.png"
      style={{
        maxWidth: 200,
        margin: '50px 0',
      }}
    />
    <HeadText>Welcome to Fablo,<br />explore the demo as:</HeadText>
    <ButtonsGrid style={{ margin: '36px 0 50px' }}>

      {!onMobile
        ? <Popover
          content={<QRCode />}
          placement="right"
          title={<div style={{
            fontSize: 16,
            fontWeight: 600,
            color: 'var(--primary)',
            textAlign: 'center',
          }}
          >Guest view in only available<br /> from a mobile device, please<br /> scan the QR below</div>}
        >
          <div style={{ gridColumn: "span 2" }}>
            <SectionButton
              text="Guest"
              href="/hotel"
              icon="Business-ManWoman"
              disabled
              style={{}}
            />
          </div>
        </Popover>
        : <div style={{ gridColumn: "span 2" }}>
          <SectionButton
            text="Guest"
            href="/hotel"
            icon="Business-ManWoman"
            style={{}}
          />
        </div>}

      <SectionButton
        text="Hotel"
        href={hasDemoPanel ? config.panel_url : 'https://fablo.eu/demo-contact'}
        icon="Hotel"
        style={{ gridColumn: "span 2" }}
      />
    </ButtonsGrid>
    <a
      href="https://fablo.eu/"
      style={{
        color: 'var(--primary)',
        textDecoration: 'underline',
      }}>More about Fablo <ArrowRightOutlined /></a>
    <p style={{
      marginTop: 50,
      fontSize: 12,
    }}>Powered by
      <a href="https://retailic.com/">
        <img src="/logo_footer.png" style={{ height: 9, marginLeft: 20, verticalAlign: 'baseline' }} />
      </a>
    </p>
  </div>
}


const RouterPage = inject('state')(observer(({ state }) => {
  const { path, url } = useRouteMatch();
  const qrCookie = readQrCodeCookie() || {}
  const hotelId = ('' + (qrCookie['hotel-id'] || ''))

  useEffect(() => {
    state.setStore(hotelId)
    if (["29", "33"].includes(hotelId)) {
      state.setLang("en")
    }
  }, [])

  const color = state.currentStoreData ? state.currentStoreData.hotel_config?.primary_color : '#fff'
  const isDemo = state.currentStoreData ? state.currentStoreData.hotel_config?.demo : true

  return <Wrapper>
    <GlobalStyle />
    <GlobalStyle2 primaryColor={color} /> {/* tmp */}

    <Switch>
      <Route exact path={path}>
        {isDemo
          ? <WelcomePage hotelId={hotelId} key={state.lang} />
          : <Redirect to={`${path}/restaurant/welcome`} />
        }
      </Route>
      <Route path={`${path}/demo`} >
        <DemoPage hotelId={hotelId} hasDemoPanel={!!qrCookie['qr-id']} />
      </Route>
      <Route path={`${path}/breakfast`} component={BreakfastPageInner} />
      {/*<Route path={`${path}/services/:type`}>
        <OrderServicePage hotelId={hotelId} key={state.lang} />
      </Route>*/}
      <Route path={`${path}/services/welcome`}>
        <ServicesSections hotelId={hotelId} key={state.lang} />
      </Route>
      <Route path={`${path}/services`}>
        <ServicesPage hotelId={hotelId} key={state.lang} />
      </Route>
      <Route path={`${path}/restaurant:num(\\d?)/menu`}>
        {({match}) => <RestaurantMenuPage hotelId={hotelId} lang={state.lang} restaurantNum={match.params.num || ''} />}
      </Route>
      <Route path={`${path}/restaurant:num(\\d?)/welcome`}>
        {({match}) => <RestaurantPage hotelId={hotelId} key={state.lang} restaurantNum={match.params.num || ''} /> }
      </Route>
      <Route path={`${path}/restaurant`}>
        <RestaurantSections hotelId={hotelId} key={state.lang} />
      </Route>

      <Route path={`${path}/other`}>
        <OrderServicePage hotelId={hotelId} key={state.lang} />
      </Route>
      <Route path={`${path}/explore`} exact>
        <ExplorePage hotelId={hotelId} key={state.lang} />
      </Route>
      <Route path={`${path}/explore/:provider`}>
        {({match}) => <ServicesPage hotelId={hotelId} key={state.lang} provider={match.params.provider} />}
      </Route>

      <Route path={`${path}/shop/welcome`}>
        <ShoppingSections hotelId={hotelId} key={state.lang} />
      </Route>
      <Route path={`${path}/shop`}>
        <ShopPage hotelId={hotelId} key={state.lang} />
      </Route>
    </Switch>
  </Wrapper>
}))

export default RouterPage
