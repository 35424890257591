import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Button as AntButton,
  Modal,
  Input as AntInput,
  Spin,
} from 'antd'
import { PlusOutlined, MinusOutlined, QrcodeOutlined } from '@ant-design/icons';
import styled, { css, createGlobalStyle } from 'styled-components'
import { state } from '../models/AppState'
import { Link, } from "react-router-dom";


const LangSwitch = inject('state')(observer(({ state }) => {
  return <div style={{ fontSize: '16px' }}>
    <a
      onClick={() => {
        const lang = state.lang == 'en' ? 'pl' : 'en'
        state.setLang(lang)
        gaEvent('lang_changed', { lang: lang })
      }}
      style={{ color: 'var(--primary)', fontWeight: 500 }}
    >{state.lang == 'en' ? "POLISH" : "ENGLISH"} ▸</a>
  </div>
}))

const Header = ({ hotelId }) => {
  return <div style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }}>
    <Link to="/hotel">
      <img
        src={`/api/shop/${hotelId}/image`}
        style={{ maxWidth: 120, maxHeight: 52, margin: 4 }}
      />
    </Link>
    <LangSwitch />
  </div>
}

const Header2 = ({ hotelId }) => {
  return <div style={{ textAlign: 'center' }}>
    <LangSwitch />
    <Link to="/hotel">
      <img
        src={`/api/shop/${hotelId}/image`}
        style={{ maxWidth: 220, marginTop: 30, maxHeight: 150, marginBottom: 46 }}
      />
    </Link>
  </div>
}

const HeadText = styled.p`
  font-weight: bold;
  color: var(--primary);
  margin-bottom: 0;
  padding: 0 24px 24px;
  font-size: 26px;
  line-height: 1.2em;
`

const ContactButton = () => (
  <a
    href={"tel:" + (state.currentStoreData && state.currentStoreData.phone)}
    onClick={() => gaEvent('contact_frontdesk')}
    style={{
      padding: '14px 24px',
      display: 'block',
      border: '2px solid var(--primary)',
      borderRadius: 20,
      color: 'var(--primary)',
      maxWidth: 260,
      fontWeight: 600,
      margin: '12px auto',
      textAlign: 'left',
    }}
  >
    <i className="icon-Phone-2" style={{ fontSize: 52, float: 'left', marginRight: 24, }} />
    {t({
      pl: 'Poinformuj',
      en: 'Contact'
    })}<br />{t({ pl: 'Recepcję', en: 'Front desk' })}
  </a>
)

const Wrapper = styled.div`
max-width: 600px;
margin: 0 auto;
font-family: 'FS Lola';
//--primary: #509E2F;
font-size: 20px;
padding: 12px;
`
const GlobalStyle = createGlobalStyle`
.ant-btn-primary {
  background: var(--primary);
  border-color: var(--primary);
  &:hover { color: #fff; }
  &:focus {
    background: var(--primary);
    border-color: var(--primary);
  }
}
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: var(--primary);
}

.ant-form-item-label > label {
  font-size: 16px;
}

/* temporary */
@font-face {
  font-family: 'FS Lola';
  src: url("/css/FSLola.eot");
  src: url("/css/FSLola.eot?#iefix") format("embedded-opentype"), url("/css/FSLola.woff2") format("woff2"), url("/css/FSLola.woff") format("woff"), url("/css/FSLola.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FS Lola';
  src: url("/css/FSLola-Medium.eot");
  src: url("/css/FSLola-Medium.eot?#iefix") format("embedded-opentype"), url("/css/FSLola-Medium.woff2") format("woff2"), url("/css/FSLola-Medium.woff") format("woff"), url("/css/FSLola-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

  @font-face {
    font-family: 'FS Lola';
    src: url("/css/FSLola-MediumItalic.eot");
    src: url("/css/FSLola-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/css/FSLola-MediumItalic.woff2") format("woff2"), url("/css/FSLola-MediumItalic.woff") format("woff"), url("/css/FSLola-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic; }

`

const useGAScreen = (name) => {
  return useEffect(() => {
    window.gtag('event', 'screen_view', {
      'screen_name': name
    })
  }, [])
}
const gaEvent = (name, params) => {
  window.gtag('event', name, params)
}
const gaSet = (vals) => {
  window.gtag('set', vals)
}

const getCookieValue = (name) => {
  try {
    return decodeURIComponent(document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '')
  } catch (e) {
    return ''
  }
}

const readQrCodeCookie = () => {
  try {
    return JSON.parse(getCookieValue('qrcode') || '{}')
  } catch (e) {
    return {}
  }
}

const t = (x) => {
  const lang = state.lang
  return x[lang] || x['pl'] || x['en'] || ''
}

// --primary #509E2F
const Button = styled(AntButton)`
&:hover, &:focus {
  border-color: var(--primary);
  color: var(--primary);
}
&.ant-btn-primary {
  background: var(--primary);
  border-color: var(--primary);
  &:hover { color: #fff; }
}
&.ant-btn-link:focus {
  border-color: transparent;
}
&.ant-btn-lg {
  font-size: 20px;
  height: 48px;
}
&.ant-btn-primary[disabled] {
  background: #f5f5f5;
  border-color: #d9d9d9;
  color: #d9d9d9;
}
`

const CartButton = ({ limit, inCart, setInCart, addDisabled }) => {
  return <div style={{ display: 'flex' }}>
    <Button
      shape="circle"
      icon={<MinusOutlined />}
      disabled={inCart <= 0}
      onClick={() => setInCart(inCart - 1)}
    />
    <div style={{
      flex: 1,
      fontSize: 24,
      display: 'inline-block',
      textAlign: 'center',
    }}>{inCart}</div>
    <Button
      shape="circle"
      icon={<PlusOutlined />}
      onClick={(e) => setInCart(inCart + 1)}
      disabled={inCart >= limit || addDisabled}
    />
  </div>
}

const ScanQRAgain = () => {
  useGAScreen('ScanQRAgainScreen')

  return <Wrapper style={{
    paddingTop: 100,
    textAlign: 'center',
  }}>
    <QrcodeOutlined style={{ fontSize: 64, marginBottom: 50 }} />
    <h2>Zeskanuj ponownie kod QR</h2>
    <h2>Scan the QR code again</h2>
  </Wrapper>
}

const ProvidersData = {
  zoo: {
    bg: "/explore/zoo-bg.jpg",
    logo: "/explore/zoo-logo.jpg",
    name: "ZOO",
    category: "Entertainment",
    hours: "9:00 - 18:00",
    distance: "8 km",
    address: "al. Jana Pawła II 65, 01-038 Warszawa",
    desc: {
      pl: "Idealne miejsce na spacer dla miłośników zwierząt: karmienia pokazowe, zajęcia edukacyjne. Odpowiednie dla dzieci w każdym wieku oraz dorosłych, dla każdego znajdzie się coś ciekawego. Miłośnicy natury, zwierząt, historii czy spacerów na pewno nie będą się nudzić.",
      en: "The perfect place for a walk for animal lovers: demonstration feeding, educational activities. Suitable for children of all ages and adults, there is something for everyone. Lovers of nature, animals, history or walks will not be bored."
    }
  },
  mpw: {
    bg: "/explore/museum-bg.jpg",
    logo: "/explore/museum-logo.jpg",
    name: "Museum",
    category: "Museum",
    hours: "10:00 - 18:00",
    distance: "4 km",
    address: "ul. Marszałkowska 1, 00-026 Warszawa",
    desc: {
      pl: "Nasze muzeum jest jednym z najstarszych w Warszawie. Idealne miejsce aby w cichym i klimatycznych murach zanurzyć się w historii Warszawy pod opieką naszych przewodników bądź samemu. Odpowiednie dla wszystkich, udostępniamy wiele ścieżek zwiedzania z możliwością dopasowania do swoich zainteresowań oraz wieku.",
      en: "Our museum is one of the oldest in Warsaw. An ideal place to immerse yourself in the history of Warsaw in the quiet and atmospheric walls under the care of our guides or by yourself. Suitable for everyone, we provide many sightseeing paths that can be adapted to your interests and age."
    }
  },
  manubbies: {
    bg: "/explore/manubbies-bg.jpg",
    logo: "/explore/manubbies-logo.jpg",
    name: "Manubbies - The passion of making",
    category: "For kids",
    hours: "8:00 - 17:00",
    distance: "10 km",
    address: "Ul. Raabego 2c/159 02-793 Warszawa",
    desc: "Manubbies is a brand of innovative workshops for children (7-15 years old) that combine manual work with supporting the development of attitudes and skills that give a better start in life, e.g. independence, activity, responsibility, creativity, planning.",
  }
}

export {
  LangSwitch,
  Header,
  Header2,
  ContactButton,
  Wrapper,
  GlobalStyle,
  useGAScreen,
  gaEvent,
  gaSet,
  readQrCodeCookie,
  t,
  HeadText,
  CartButton,
  Button,
  ScanQRAgain,
  ProvidersData,
}
