import React, { useLayoutEffect } from 'react'
import PageWrapper, { Container, H2 } from './PageWrapper'
import { inject, observer } from 'mobx-react';
import { Button, Tooltip } from 'antd';
import { InstagramOutlined, FacebookOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { imageUrl } from '../utils'
import styled, { css } from 'styled-components';

const sampleProductsSubset = (products) => {
    if(products.length <= 4) return products
    const part = products.length / 4
    return [
        products[Math.floor(0 * part + Math.random()*part)],
        products[Math.floor(1 * part + Math.random()*part)],
        products[Math.floor(2 * part + Math.random()*part)],
        products[Math.floor(3 * part + Math.random()*part)]
    ].filter(x => x)
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    width: calc(100% - 32px);
    margin-bottom: 32px;

    grid-template-areas:
        "shop-img long-desc"
        "shop-img socials"
        "shop-img prods"
        "short-desc .";

    @media (max-width: 600px) {
        grid-template-columns: 100%;
        grid-template-areas:
            "shop-img"
            "socials"
            "short-desc"
            "prods"
            "long-desc";
        width: auto;
        margin: 0 12px;
        text-align: center;
    }
    `
    const Title = styled.div`
    h2 { 
        font-size: 33px;
        @media (max-width: 600px) {
            margin-bottom: 36px;
        }
    }
    `

const ShopCardPage = inject('state')(observer(({ state }) => {
    const storeId = state.currentStore
    const slug = state.currentStoreUrlPrefix
    const socials = state.currentStoreData.socials || {}

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const store = state.currentStoreData || {}
    const products = state.currentProducts || [];
    const sampleProducts = sampleProductsSubset(products)

    return <PageWrapper>
        <Container style={{padding: '50px 0 100px'}}>
            <Title><H2>{store.name}</H2></Title>
            <p style={{
                textAlign: 'center',
                marginTop: -18,
                marginBottom: 32,
            }}>
                {store.street}, {store.postcode} {store.city}
                <Tooltip
                    placement="bottom"
                    title={<>Dane firmy: <br /><span style={{whiteSpace: 'pre-wrap'}}>{store.company}</span></>}
                ><InfoCircleOutlined style={{marginLeft: 8}}/></Tooltip>
            </p>
        
        <Grid>
            <div style={{gridArea: "shop-img"}}>
                <img
                    src={`/api/shop/${storeId}/image`}
                    style={{
                        width: '100%',
                        borderRadius: 6,
                    }}
                />
            </div>
            
            <div style={{
                gridArea: "long-desc",
                alignSelf: "end",
                whiteSpace: "pre-wrap",
            }}>
                <p style={{
                    //marginBottom: 32, 
                }}>
                    {store.long_description}
                </p>
            </div>
            
            <div style={{
                gridArea: "socials",
                alignSelf: "center",
                //flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'
            }}>
                <p style={{fontSize: 32, margin: 0 }} className="socials">
                { socials.instagram &&
                    <Tooltip title="Zobacz Instagram" style={{marginRight: 24}}>
                        <a href={`https://instagram.com/${socials.instagram}`} target="_blank">
                            <InstagramOutlined style={{color: '#666666'}}/>
                        </a>
                    </Tooltip> }
                { socials.facebook &&
                    <Tooltip title="Zobacz Facebook">
                        <a href={`https://facebook.com/${socials.facebook}`}  target="_blank">
                            <FacebookOutlined style={{color: '#666666'}}/>
                        </a>
                    </Tooltip> }
                </p>
            </div>

            <div style={{
                gridArea: "prods",
                display: 'flex',
                justifyContent: 'space-between',
                alignSelf: "end",
            }}>
                {sampleProducts.map((p) => {
                    return <Link to={`/${slug}/produkty?p=${p.id}`} key={p.id} style={{padding: '0 4px'}}>
                        <img
                            src={imageUrl(p)}
                            style={{
                                maxWidth: 100,
                                maxHeight: 85,
                                borderRadius: 6,
                            }}
                        />
                    </Link>
                })}
            </div>

            <div style={{
                gridArea: "short-desc"
            }}>
                <p style={{
                    margin: 0,
                    fontSize: 18,
                }}>
                    {store.description}
                </p>

            </div>

            <div>
                
            </div>
        </Grid>
        <div style={{textAlign: "center"}}>
            <Link to={`/${slug}/produkty`}>
                <Button type="primary">
                    WEJDŹ DO SKLEPU
                </Button>
            </Link>
        </div>

        </Container>
    </PageWrapper>
}))

export default ShopCardPage
